.splash {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center
}

p {
  font-size: 200%;
}
.over1 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.over2 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.logo, .logo img {
  padding-top: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

  
.imageCon {
  position: relative;
  text-align: center;
}

.imageConMask {
  width: 314px;
  overflow: hidden;
  min-height: 324px;
}

.imageHidden {
  display: none;
}

img.thumbnail {
  border: 1px solid #D3D3D3;
  padding: 6px;
  margin-top: 6px;
  margin-right: 6px;
  width: 300px;
  height: 300px;
}

@media (min-width: 380px) {
  img.thumbnail {
    width: 340px;
    height: 340px;
  }
  .imageConMask {
    width: 354px;
    min-height: 364px;
  }
}


@media (min-width: 680px) {
  img.thumbnail {
    width: 500px;
    height: 500px;
  }
  .imageConMask {
    width: 514px;
    min-height: 524px;
  }
}

.fade {
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  -o-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
}

.fade-out {
  opacity: 0;
}

.fade-in {
  opacity: 1;
}

.rotater {
  animation: rotation 1s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
